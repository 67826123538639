(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Matches
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Comitetg', Comitetg);

  function Comitetg($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/comitetg/:id', {id: '@_id'},{
        get:{
            method: 'GET',
            isArray: true
        }
    });
  }
}());
